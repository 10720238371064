import React, {useState, useEffect} from 'react';
import { Link , useHistory, useParams} from 'react-router-dom';
import { render } from "react-dom";
import  './estiloform.css';
import api from '../../../services/api';
import { format, parseISO } from 'date-fns';
import Helmet from 'react-helmet';
import InputMask from "react-input-mask";

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function Register(){
const [resolucao, setresolucao] = useState('');
const [codigo, setcodigo] = useState('');
const [status, Setstatus] = useState('');
const [hora, sethora] = useState('');
const history = useHistory();
const { id_chamado } = useParams();
const [objeto, setchamados] = useState([]); 


const handleChange = (event) => {
  Setstatus(event.target.value);
};


useEffect(() => {   

  api.get('GetEditChamados/'+id_chamado, { }).then ( response => 
    { 
      setchamados(response.data);
      console.log(response.data[0].codigo);
      console.log(response.data[0].chamado);
      console.log(response.data[0].status);
      Setstatus(response.data[0].status);
      setcodigo(response.data[0].codigo);
      sethora(response.data[0].horas);
     
    })
  
  }, [id_chamado]);

async function handdleRegister(e){
  e.preventDefault();

const data = {
codigo,
hora,
status,
resolucao,
};


try{
  const response = await api.post('SetAtualizarChamado', data);
 alert(' ATUALIZADO COM SUCESSO');
 history.push('/educacao/controle/chamados');   
  
    } catch (err){
     alert('Erro no solicitação');
 }
}


return(
  <>
   <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            onClick={() => {
              history.push('/educacao/controle/dashboard')
            }}
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            Menu
          </Typography>
          <Search>
            <SearchIconWrapper>
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="pesquisar…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </Toolbar>
      </AppBar>
    </Box>

 <div className="dashboard">
        <div className="content_novo">
  <Helmet title="IDATADEV SISTEMAS - Capinzal - SC" />
     <h4>Atualização de Chamado - Atendimento</h4>

     {objeto.map(dados_carregados => (
     <li className="li" justify-content= "center" >   
     <li  key={dados_carregados.codigo} /> 
    
        <form onSubmit={handdleRegister} >
        <Box sx={{ minWidth: 150 }}>  
        <label className='teste-label'>  
        <FormControl>
        Código Chamado: {dados_carregados.codigo}
        <br></br>     
        Data Abertura : {format(parseISO(dados_carregados.data_abertura), 'dd/MM/yyyy hh:mm:ss') }
        </FormControl>
        </label> 
        </Box>
        
        <Box sx={{ minWidth: 250 }}>  
        <label className='teste-label'> 
        <FormControl>
        Quantiadade Horas - {dados_carregados.horas} 
        <InputMask className="codigo" mask="99:99:99" 
          value={hora} 
          onChange={e => sethora(e.target.value)}  />
        </FormControl>
        </label>
        </Box>  
        
        <Box sx={{ minWidth: 250 }}> 
        <label className='teste-label'> 
        <FormControl>  
         Origem Chamado:<br></br> 
         {dados_carregados.nome}
        </FormControl>
        </label> 
        </Box>

        <label className='teste-label'> 
        <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label"><b> Chamado </b></FormLabel>
        <p><b>{dados_carregados.chamado}</b></p>
        </FormControl>
        </label>
        <br></br>
          <label className='teste-label'> 
          <FormControl>   
          <Box sx={{ minWidth: 300 }}>
          <FormControl>
          <br></br>
          <InputLabel id="demo-simple-select-label"><b>Status Chamado</b></InputLabel>
          <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          label="Status Chamado"
          onChange={handleChange}>
          <MenuItem value={1}>NOVO</MenuItem>
          <MenuItem value={2}>EM ANDAMENTO</MenuItem>
          <MenuItem value={3}>ENCERRADO ATENDIMENTO</MenuItem>
          <MenuItem value={4}>AGUARDANDO FORNECEDOR</MenuItem>
          <MenuItem value={5}>AGUARDANDO ORÇAMENTO</MenuItem>
          <MenuItem value={6}>AGUARDANDO APROVAÇÃO</MenuItem>
          <MenuItem value={7}>CANCELADO</MenuItem>
          <MenuItem value={8}>CONCLUIDO</MenuItem>
          <MenuItem value={8}>APROVADO</MenuItem>
          </Select>
          </FormControl>
          </Box>  
          </FormControl>
          </label>
          
          <Box sx={{ minWidth: 300 }}>
          
        <label className='teste-label'> 
        <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">Obervação</FormLabel>
                 <textarea   required
                 value={dados_carregados.resolucao}
                  rows={3}
                  cols={40}
                  onChange={e => setresolucao(e.target.value)}/>
        </FormControl>
        </label>
        </Box>
      
            <button className="btgrava" type="submit">Atualizar Chamado</button>
        </form>
     <br></br>
    
      </li> 
    ))}
  </div> 
  </div>
              
<div className="rodape1">
        <Link to={{ pathname: "https://idatadev.com.br" }} target="_blank" > &copy;2022 - IDataDev Sistemas Computacionais  <br></br> Capinzal - SC - CNPJ: 22.463.183/0001-14</Link>
      
 </div>
 
  </>
      );

  }

