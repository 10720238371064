import React, {useState, useEffect, useCallback, StyleSheet, View, Image} from 'react';
import { FiLogIn, FiPower, FiTrash2, FiSend, FiArrowLeft, FiCheck, fiwhatsapp, FiShoppingCart } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import './estilo_newchamado.css';
import { render } from "react-dom";
import api from '../../../services/api';
import 'react-slideshow-image/dist/styles.css'
import { format, parseISO } from 'date-fns';
import Helmet from 'react-helmet';
import ReactWhatsapp from 'react-whatsapp';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import InputMask from "react-input-mask";
import { mask as masker, unMask } from "remask";
import { useMediaQuery } from 'react-responsive';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));



const InputMask2 = ({ mask, onChange, value, ...props }) => {
  const handleChange = ev => {
    const originalValue = unMask(ev.target.value);
    // const maskedValue = masker(originalValue, mask);
    onChange(originalValue);
  };

};

const options = [
  'NORMAL',
  'URGENTE'
   ];

  const defaultOption = options[-1];

export default function New(){

 
const [tipo_chamado, settipo] = useState('NORMAL');
const [chamado, setchamado] = useState('');

  const history = useHistory();
  const [listap, setqueryempresa] = useState([]); 

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }

  const cod_empresa =  localStorage.getItem('cod_empresa');
  const cod_usuario =  localStorage.getItem('cod_usuario');
 
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

 
  async function  handlePeriodChange(selVal) {
    this.props.handlePeriodChange(selVal);
}




async function Registerchamado(e){
  e.preventDefault();

const data = {
  tipo_chamado,
  chamado,
  cod_empresa,
  cod_usuario
};


try{ 
  const response1 = await api.post('SetGravaChamado', data);
   alert('CHAMADO RESGISTRADO COM SUCESSO!');
      history.push('/educacao/controle/chamados');

    } catch (err)
       {
         alert('ERRO GERAL' + err);
         history.push('/educacao/controle/Novochamado');
        }
}

//  <img className="" srcSet={'https://idatadev.com.br/sistema/chamados/logo.jpeg'}  />  
   
    return (
      <>
     
        <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            onClick={() => {
              history.push('/educacao/controle/dashboard')
            }}
           // onclick={history.push('/educacao/controle/dashboard')}
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            Menu
          </Typography>
          <Search>
            <SearchIconWrapper>
             </SearchIconWrapper>
            <StyledInputBase
              placeholder="pesquisar…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </Toolbar>
      </AppBar>
    </Box>

    <div className="newchamado">
        <div className="content_novo">

             <Helmet title="Cadastro do Chamado - IDATADEV SISTEMAS" />      
       
        <h4>Cadastre o chamado para atendimento</h4>
  
          <form onSubmit={Registerchamado}  className="form_central">
  
          <label> 
          <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Escolha tipo chamado</FormLabel>
          <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          >
         <FormControlLabel value="NORMAL" control={<Radio />}  onChange={e => settipo(e.target.value)} label="NORMAL" />
         <FormControlLabel value="URGENTE" control={<Radio />}  onChange={e => settipo(e.target.value)} label="URGENTE" />
         </RadioGroup>
         </FormControl>
        </label>
        
        <label> 
          Chamado: <br></br>
           <textarea  placeholder="Descreva o chamado..."  required
                  value={chamado}
                  rows={5}
                  cols={35}
                  onChange={e => setchamado(e.target.value)}/>
          </label>
           <button className="btregistro" type="submit">Cadastrar</button>
          </form>
       </div>
       </div>

<div className="rodape1">
<Link  href="https://www.instagram.com/idatadev">&copy;2022 - Plataforma S2QRCODE</Link>
 </div>
 </>      
            
  );
}


