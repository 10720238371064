import React, {useState, useEffect} from 'react';

import { FiArrowLeft } from 'react-icons/fi';
import { Link , useHistory} from 'react-router-dom';

import  './styles.css';
import imglogo from '../../img/b1.png';
import api from '../services/api';
import { format, parseISO } from 'date-fns';
import Helmet from 'react-helmet';

import Carousel, { Modal, ModalGateway } from 'react-images'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import InputMask from "react-input-mask";



const images2 = [{ source: 'https://idatadev.com.br/aniversario2.jpg' }, { source: 'https://idatadev.com.br/aniversario1.jpg' }]

class Component extends React.Component {
  state = { modalIsOpen: false }
  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }))
  }
  render() {
    const { modalIsOpen } = this.state

    return (
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={this.toggleModal}>
            <Carousel views={images2} />
          </Modal>
        ) : null}
      </ModalGateway>
    )
  }
}


const images1 = [
  'https://idatadev.com.br/aniversario1.jpg'
];



export default function Register(){
const [nome, setnome] = useState('');
const [cnpj, setcnpj] = useState('');
const [responsavel, setresponsavel] = useState('');
const [email, setemail] = useState('');
const [fone, setfone] = useState('');
const [sistema, setsistema] = useState('');
const [cidade, setcidade] = useState('');
const [estado, setestado] = useState('');
const [vendedor, setvendedor] = useState('');

const history = useHistory();

async function handdleRegister(e){
  e.preventDefault();

const data = {
nome,
cnpj,
responsavel,
email,
fone,
sistema,
cidade,
estado,
vendedor
};


try{
  console.log(JSON.stringify(data));
const response = await api.post('SetGravaPreCad', data);

alert(' SOLICITAÇÃO FOI SALVA COM SUCESSO');
alert(' Entraremos em contato para prosseguir com projeto!');
    
// alert(' SOLICITAÇÃO FOI SALVA COM SUCESSO -> Seu código: -->> ' + response.data[0].insertId +'  <<-- Agora siga nosso instagram - @IDATADEV ');
      console.log(response.data[0].insertId); 
  
      window.location.replace('https://www.idatadev.com.br');
  
    } catch (err){
     alert('Erro no solicitação');
 }
}



class LightboxExample extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    return (
      <div>
      {isOpen && (
          <Lightbox
  //          mainSrc={images1[photoIndex]}
    //        nextSrc={images1[(photoIndex + 1) % images1.length]}
      //      prevSrc={images1[(photoIndex + images1.length - 1) % images1.length]}
        //    onCloseRequest={() => this.setState({ isOpen: false })}
          //  onMovePrevRequest={() =>
            //  this.setState({
              //  photoIndex: (photoIndex + images1.length - 1) % images1.length,
           //   })
          //  }
         //   onMoveNextRequest={() =>
           //   this.setState({
            //    photoIndex: (photoIndex + 1) % images1.length,
           //   })
          //  }
          />
        )}
      </div>
    );
  }
}


return(
<div  className="container">
  <div class="wrapper">
  <div class="row">

<div class="col-md-6">
  </div>
  <div class="gg">
        
  
  <div class="div_cabe">
  <Helmet title="Solicite aqui sua licença para Plataforma QRCODE - IDATADEV SISTEMAS - Capinzal - SC" />
     <h4>Plataforma Gestão Promocional S2QR CODE</h4>
       <p>Pré cadastro para implantação do projeto </p>   
  
  
  {/* Código desativado 
  
     <h7>Sorteio será ONLINE dia: 04/04/2021 às 11:30 horas </h7> - 
    <Link  to="/registro_extrato_promocao" > Lista dos participantes aqui!</Link>   */}


    <p></p>

    {/*  <Carousel className="imgpromo_novidade" views={images2} />*/}

    
        <form onSubmit={handdleRegister}  className="form_central">
         
         <input className="edit1" placeholder="Empresa"  required
                value={nome}
                onChange={e => setnome(e.target.value)}/>

        <input className="edit1" placeholder="CNPJ"  required  
                value={cnpj}
                onChange={e => setcnpj(e.target.value)}/>
              
          <input className="edit1" placeholder="Responsável"  required
                value={responsavel}
                onChange={e => setresponsavel(e.target.value)}/>  

         <input  className="edit1" type="email" placeholder="E-mail..." required
                value={email}
                onChange={e => setemail(e.target.value)} />

        <InputMask className="edit1" mask="(99)99999-9999" maskChar={null} value={fone} placeholder="DDD + NÚMERO"  onChange={e => setfone(e.target.value)} required />
      

         <input  className="edit1" type="sistema" placeholder="Sistema venda atual" required
                value={sistema}
                onChange={e => setsistema(e.target.value)} />

         <input  className="edit1" type="cidade" placeholder="Cidade" 
                value={cidade}
                onChange={e => setcidade(e.target.value)} />

         <input  className="edit1" type="estado" placeholder="Estado" 
                value={estado}
                onChange={e => setestado(e.target.value)} />
       
        <input  className="edit1" type="vendedor" placeholder="Vendedor / Consultor" 
                value={vendedor}
                onChange={e => setvendedor(e.target.value)} />

         <button className="bt1salvar" type="submit">Enviar cadastro</button>



        </form>
      
      
        </div>
  
        </div>
              </div>

              
<div className="rodape1">
        <Link to={{ pathname: "https://idatadev.com.br" }} target="_blank" > &copy;2021 - IDataDev Sistemas Computacionais  <br></br> Capinzal - SC - CNPJ: 22.463.183/0001-14</Link>
      
 
</div>
  </div>

  

  </div>
    
      );


}



