import React, {useState, useEffect} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import './estilo_chamado.css';
import { render } from "react-dom";
import api from '../../../services/api';
import { Zoom , Slide} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { format, parseISO } from 'date-fns';
import Helmet from 'react-helmet';
import ReactWhatsapp from 'react-whatsapp';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import InputMask from "react-input-mask";
import { mask as masker, unMask } from "remask";
import { useMediaQuery } from 'react-responsive';
import Table from 'react-bootstrap/Table'

import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));


export default function Chamados(){

  const [value, setValue] = useState("");
  const [telefone, settelefone] = useState('');
 
//  const [cpf, setCpf] = useState('');
//  const [cod_empresa, setcod_empresa] = useState('');
const [nome_cliente, setnome_cliente] = useState('');
  
  const history = useHistory();

  const [listap, setchamados] = useState([]); 

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }

  const cod_empresa =  localStorage.getItem('cod_empresa');
  const cod_usuario =  localStorage.getItem('cod_usuario');
  const cargo =  localStorage.getItem('cargo');
    
  //const { cod_empresa } = useParams ();
  useEffect(() => {   



    localStorage.getItem('cod_empresa', cod_empresa);
    localStorage.getItem('cod_usuario', cod_usuario);
     
    if (localStorage.getItem('cargo', cargo)  === 'FORNECEDOR'){
       
      api.get('GetConsultaChamadosFornecedor/'+cod_empresa+'/'+cod_usuario,{ }).then ( response => {
        setchamados(response.data); 
        })
      
    }
    else
    {
     api.get('GetConsultaChamados/'+cod_empresa+'/'+cod_usuario,{ }).then ( response => {
      setchamados(response.data); 
      })
    }

    }, [cargo]);
  
  



  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })


async function LoginRegister(e){
  e.preventDefault();

const data = {
telefone,
value
};
}
//  <img className="" srcSet={'https://idatadev.com.br/sistema/chamados/logo.jpeg'}  />  
   
    return (
      <>

<Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            onClick={() => {
              history.push('/educacao/controle/dashboard')
            }}
           // onclick={history.push('/educacao/controle/dashboard')}
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            Menu
          </Typography>
          <Search>
            <SearchIconWrapper>
             </SearchIconWrapper>
            <StyledInputBase
              placeholder="pesquisar…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </Toolbar>
      </AppBar>
    </Box>
  
      <div className="chamados_novos">
      <p  className="pteste" >Lista de Chamados </p>
        <Helmet title="Lista de Chamados - IDATADEV SISTEMAS" />                  
       
  <Table striped bordered hover >
  
 
   <thead>
   <tr>
      <th>Código</th>
      <th>Tipo</th>
      <th>Chamado</th>
      <th>Data Abertura</th>
      <th>Status Atual</th>
      <th>Horas</th>
      <th>Responsável</th>
      <th>email</th>
      <th>Opção</th>
      </tr>
  </thead>
  <tbody>
      { 
         listap.map(chamado => (
          <tr>
                <td> {chamado.codigo} </td>
                <td> <span>{chamado.tipo_chamado}</span> </td>
                <td> <span>{chamado.chamado}</span></td>
                <td>  { format(parseISO(chamado.data_abertura), 'dd/MM/yyyy hh:mm:ss')}</td>
                <td> {chamado.STATUS_ATUAL}</td>
                <td> {chamado.horas}</td>
                <td> {chamado.fornecedor}</td>
                <td> <span>{chamado.email}</span></td>
                <td> <Link to={'editarchamado/'+chamado.codigo}><button className="editar" value={chamado.codigo}> Visualizar</button></Link> </td>
                </tr>
                              )
                ) 

                }  
  </tbody>
</Table>  
  
       </div>

         <div className="rodape1">
         <Link  href="https://www.instagram.com/idatadev">&copy;2022 - IDataDev Sistemas Computacionais<br></br>CPNJ: 22.463.183/00001-14</Link>
         </div>
       </>
  );
}


