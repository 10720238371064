import React, {useState, useEffect} from 'react';

import { FiArrowLeft } from 'react-icons/fi';
import { Link , useHistory} from 'react-router-dom';

import  './styles.css';
import imglogo from '../../img/b1.png';
import api from '../services/api';
import { format, parseISO } from 'date-fns';
import Helmet from 'react-helmet';

import Carousel, { Modal, ModalGateway } from 'react-images'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import InputMask from "react-input-mask";





const images2 = [{ source: 'https://idatadev.com.br/plan1geral.jpg' }, { source: 'https://idatadev.com.br/promo1.jpg' }]

class Component extends React.Component {
  state = { modalIsOpen: false }
  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }))
  }
  render() {
    const { modalIsOpen } = this.state

    return (
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={this.toggleModal}>
            <Carousel views={images2} />
          </Modal>
        ) : null}
      </ModalGateway>
    )
  }
}


const images1 = [
  'https://idatadev.com.br/aniversario1.jpg'
];



export default function Register(){
const [nome, setName] = useState('');
const [email, setEmail] = useState('');
const [fone, setfone] = useState('');
const [instagram, setinstagram] = useState('');


const history = useHistory();

async function handdleRegister(e){
  e.preventDefault();

const data = {
nome,
email,
fone,
instagram
};


try{
  console.log(JSON.stringify(data));
const response = await api.post('SetGravaPromoaoCap', data);
      alert(' Recebemos seu contato! Seu PROTOCOLO: -->> ' + response.data[0].insertId +'  <<-- Receberá um SMS de confirmação!');
      console.log(response.data[0].insertId); 
  
      window.location.replace('https://www.instagram.com/idatadev');
  
    } catch (err){
     alert('Seus dados já esta na nossa base entraremos em contato');
     window.location.replace('https://www.instagram.com/idatadev');
 }
}


class LightboxExample extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    return (
      <div>
        {isOpen && (
          <Lightbox
            mainSrc={images1[photoIndex]}
            nextSrc={images1[(photoIndex + 1) % images1.length]}
            prevSrc={images1[(photoIndex + images1.length - 1) % images1.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images1.length - 1) % images1.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images1.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

// <Link  to="/registro_extrato_promocao" > Lista dos participantes aqui!</Link>
//<p>Plataforma Gestão Promocional S2QRCODE </p>   

//'-webkit-background-size: cover;'+'\n'+
//'-moz-background-size: cover;'+'\n'+
//'-o-background-size: cover;'+'\n'+
//'background-size: cover;'+'\n'+
//'background-image: url(https://idatadev.com.br/'+PARAMETRO2+'_fundo.png);'+'\n'+

return(
<div  className="container">
    
        

  <div class="gg">

  <div class="div_central">
  <Helmet title="Cadastre-se e receber orçamento da nossa plataforma - IDATADEV SISTEMAS - Capinzal - SC" />
     <h4>Cadastre-se para solicitar um orçamento para sua empresa</h4>
      
    <p></p>


        <form onSubmit={handdleRegister}  className="form_central">
         <input className="edit1" placeholder="Empresa"  required
                value={nome}
                onChange={e => setName(e.target.value)}/>
         <input  className="edit1" type="email" placeholder="E-mail..." required
                value={email}
                onChange={e => setEmail(e.target.value)} />
       
          <InputMask className="edit1" mask="(99)99999-9999" maskChar={null} value={fone} placeholder="DDD + NÚMERO"  onChange={e => setfone(e.target.value)} required />
         
         <button className="bt1salvar" type="submit">Cadastre-se aqui</button>

        </form>
      

        <Carousel className="imgpromo_novidade" views={images2}      
     
     />
      
    </div>

              
<div className="rodape1">
        <Link to={{ pathname: "https://idatadev.com.br" }} target="_blank" > &copy;2021 - IDataDev Sistemas Computacionais  <br></br> Capinzal - SC CNPJ: 22.463.183/0001-14</Link>
      


 
</div>
  </div>

  

    </div>

    
      );


}



