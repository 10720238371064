import React, {useState, useEffect, useCallback, StyleSheet, View, Image} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import './styles.css';
import 'react-slideshow-image/dist/styles.css'
import Helmet from 'react-helmet';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import styled from "styled-components";
import { mask as masker, unMask } from "remask";
import { useMediaQuery } from 'react-responsive';

const Layout = styled.div`
  padding: 12px;
`;
const Input = styled.input`
  padding: 12px;
  margin-top : 5px;
  font-size: 1.1rem;
`;


const InputMask2 = ({ mask, onChange, value, ...props }) => {
  const handleChange = ev => {
    const originalValue = unMask(ev.target.value);
    // const maskedValue = masker(originalValue, mask);
    onChange(originalValue);
  };

  const handleValue = masker(value, mask);

  return <Input {...props} onChange={handleChange} value={handleValue} />;
};


export default function Login(){

  const [value, setValue] = useState("");
  const [telefone, settelefone] = useState('');
  const [nome_cliente, setnome_cliente] = useState('');  
  const history = useHistory();
  const [listap, setqueryempresa] = useState([]); 

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }

  const cod_empresa =  localStorage.getItem('cod_empresa');

  const { id } = useParams ();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

 
  async function  handlePeriodChange(selVal) {
    this.props.handlePeriodChange(selVal);
}




async function LoginRegister(e){
  e.preventDefault();
 history.push('/educacao/controle/dashboard_biblioteca');
}

    
  return (
    
    <div className="container1_b">
          <Helmet title="Biblioteca Pública Central - VEREADOR ROLINDO CASAGRANDE - IDATADEV SISTEMAS" /> 
      <div className="LoginContainer1_b">
    
        <h5> <p>Bem-vindo!</p></h5>    
     <h3> <p>BIBLIOTECA PÚBLICA MUNICIPAL</p></h3>
     <h5> <p>"VEREADOR ROLINDO CASAGRANDE" </p></h5>  
        <img className="img_desc" srcSet={'https://idatadev.com.br/sistema/chamados/logo.jpeg'}  />     
         
        <form onSubmit={LoginRegister}>       
         <Layout>
         <button  className="bta" type="submit">Entrar</button>
         </Layout>
        </form>
        <div className="rodape1">Capinzal - SC <br></br>
        &copy;2022 - IDataDev Sistemas Computacionais  <br></br>      
       </div>

       </div>
  
       
    </div>
   
  
  );
}


