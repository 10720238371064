import React, {useState, useEffect, useCallback, StyleSheet, View, Image} from 'react';
import { FiLogIn, FiPower, FiTrash2, FiSend, FiArrowLeft, FiCheck, fiwhatsapp, FiShoppingCart } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import './styles.css';
import { render } from "react-dom";
import api from '../../services/api';
import { Zoom , Slide} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { format, parseISO } from 'date-fns';
import Helmet from 'react-helmet';
import ReactWhatsapp from 'react-whatsapp';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import InputMask from "react-input-mask";
import styled from "styled-components";
import { mask as masker, unMask } from "remask";
import { useMediaQuery } from 'react-responsive';

const Layout = styled.div`
  padding: 12px;
`;
const Input = styled.input`
  padding: 12px;
  margin-top : 5px;
  font-size: 1.1rem;
`;


const InputMask2 = ({ mask, onChange, value, ...props }) => {
  const handleChange = ev => {
    const originalValue = unMask(ev.target.value);
    // const maskedValue = masker(originalValue, mask);
    onChange(originalValue);
  };

  const handleValue = masker(value, mask);

  return <Input {...props} onChange={handleChange} value={handleValue} />;
};


export default function Login(){

  const [value, setValue] = useState("");
  const [telefone, settelefone] = useState('');
 
//  const [cpf, setCpf] = useState('');
//  const [cod_empresa, setcod_empresa] = useState('');
const [nome_cliente, setnome_cliente] = useState('');
  
  const history = useHistory();
  const [listap, setqueryempresa] = useState([]); 

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }

  const cod_empresa =  localStorage.getItem('cod_empresa');
 

  const { id } = useParams ();

  useEffect(() => {
   
   console.log(id);
   localStorage.setItem('cod_empresa', id);
    localStorage.getItem('cod_empresa', id);
        api.get('GetConsultacashbackempresa_geral/'+id,{ }).then ( response => {
           setqueryempresa(response.data); 
      // setcount(response.data.length); 
      })
    
    }, [cod_empresa]);
    


  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

 
  async function  handlePeriodChange(selVal) {
    this.props.handlePeriodChange(selVal);
}


async function LoginRegister(e){
  e.preventDefault();

const data = {
telefone,
value
};



try{
  console.log(value);
  console.log(telefone);


  const response = await api.get('GetLoginCash/'+value+'/'+telefone);
  console.log(response.data);

localStorage.setItem('cpf', response.data[0].cpf);
localStorage.setItem('conta', response.data[0].codigo);
localStorage.setItem('nome_cliente', response.data[0].nome);
localStorage.setItem('cod_empresa', id);

console.log(response.data[0].cpf);
console.log(response.data[0].codigo);
console.log(response.data[0].nome);


if (response.data[0].cpf == ''){
  alert('Conta digital não encontrada!');   
}  else
history.push('/qrcode/Consultasaldocashbackemp/');


} catch(err){
//  alert('Nenhuma farmácia foi encontrada com este parâmetro: '+cep);
//  api.get('GetConsultaempresas_qrcode').then ( response => {
// setqueryprodutos(response.data);
alert('Conta digital não encontrada...');

}}

    
  return (
    <div className="container1">
      <div className="LoginContainer1">
        <Helmet title="Acesso a sua Conta Digital - IDATADEV SISTEMAS" />      
      
       {
          listap.map(empresa => (
              <li className="linha">  
              <h6><p id="nome_produto">BEM VINDO SUA CONTA DIGITAL <br></br>{empresa.nome }</p> </h6>
              <img className="img_desc" srcSet={'https://idatadev.com.br/sistema/qrcode/logos/'+id+'.png'}  />  
              </li> 
                                )
                     )
      
       }
<form onSubmit={LoginRegister}>       
<Layout>
  <div> <b>Digite o CPF ou CNPJ</b></div>
      <InputMask2
        type="text"
        name="doc"
        class="teste"
        mask={["999.999.999-99", "99.999.999/9999-99"]}
        placeholder="Digite o CPF ou CNPJ"
        onChange={setValue}
        value={value}
      />
      <p></p>
         <div><b>Os 4 últimos nº celular ou SENHA ACESSO</b></div>
         <input type='password' value={telefone} placeholder="Senha acesso" 
          onChange={e => settelefone(e.target.value)} required />
         <button className="button" type="submit">Login Conta Digital</button>
         </Layout>
        </form>
        
        
        <div className="rodape1">
        &copy;2021 - IDataDev Sistemas Computacionais  <br></br>      
        </div>
       </div>
  
      
       
       
    
    
    </div>
   

  );
}


