import React, {useState, useEffect, useCallback, StyleSheet, View, Image} from 'react';
import { FiLogIn, FiPower, FiTrash2, FiSend, FiArrowLeft, FiCheck, fiwhatsapp, FiShoppingCart } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import './styles.css';
import { render } from "react-dom";
import api from '../../services/api';
import { Zoom , Slide} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { format, parseISO } from 'date-fns';
import Helmet from 'react-helmet';
import ReactWhatsapp from 'react-whatsapp';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import InputMask from "react-input-mask";
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';


export default function Register(){
const [nome, setName] = useState('');
const [email, setEmail] = useState('');
const [telefone, settelefone] = useState('');
const [cpf, setCpf] = useState('');
const [termo, settermo] = useState('True');
const history = useHistory();

const cod_empresa =  localStorage.getItem('cod_empresa');
const link_retorno = 'https://s2code.com.br/qrcode/LoginCashback/'+cod_empresa;

function onChange(e) {
  console.log('Checkbox checked:', (e.target.checked));
  
  if (e.target.checked)
  {
   
    settermo(e.target.checked);
  } else
  {
    settermo(e.target.checked);

  }
  

} 

class SimpleDemo extends React.Component {
  state = {
    disabled: false,
  };

  toggle = () => {
    this.setState((state) => ({
      disabled: !state.disabled,
    }));
  }
}




async function handdleRegister(e){
  e.preventDefault();

const data = {
nome,
email,
telefone,
cpf,
termo
};

try{
  
  if (termo)
  {

     
    const response = await api.post('SetGravaConta_aplicador', data); 
    if (response.data == ''){
      alert('Já existe um cadastro com esses dados! '+ cpf );      
      
    } 
    else
    {
      alert('CADASTRO EFETUADO COM SUCESSO! '+response.data[0].insertId+' - Receberá um SMS com SENHA! <br> ESCANEI NOVAMENTE SEU QRCODE');      
      history.push('/qrcode/Registro_conta_aplicador/'+cod_empresa);

      const cod_cosu  = response.data[0].insertId;        
      const dadositens2 = {
        cod_cosu, 
     };   
  
    }
   
    //alert(resp);
    
    
  } 
  else
  {
   
    alert('Termo de Aceite precisa estar Marcado!');
  }
  
    
      
    } catch (err){
      alert(err); 
     alert('Erro no cadastro da sua conta digital');
    // alert(response.data[0].insertId); 
 }
}



return(
    
  <div className="container">  
  <div class="wrapper">
  <div class="row">

<div class="col-md-6">
  </div>
  <div class="gg">
        
  <div class="div_cabecalho">
  
  <Helmet title="Faça seu cadastro para acmular - CASH BACK" />
   <h3>Cadastro do Aplicador</h3>
  <p>Faça seu cadastro, para começar acumular CASH BACK</p>
  </div>
        <div class="div_cabecalho">
        <form onSubmit={handdleRegister}>
         <input className="edit1"  placeholder="Seu Nome..."  required
                value={nome}
                onChange={e => setName(e.target.value)}/>
        
       <InputMask className="edit1" mask="(99)99999-9999" maskChar={null} value={telefone} placeholder="DDD + NÚMERO" 
        onChange={e => settelefone(e.target.value)} required />
         

          <input  className="edit1" type="email" placeholder="E-mail..." required
                value={email}
                onChange={e => setEmail(e.target.value)} />
          
          <InputMask className="edit1" mask="999.999.999/9999-99" maskChar={null} value={cpf} placeholder="CNPJ" 
        onChange={e => setCpf(e.target.value)} required />

              <Checkbox className="ck"
                defaultChecked
                onChange={onChange}
             //   disabled={this.state.disabled}
              /> <span className="termo">Termos e Condições de Aceite e Uso da plataforma são regidos pela legislação brasileira atendendo a política de dados conforme a
               Lei Geral de Proteção de Dados Pessoais (LGPD), Lei nº 13.709, de 14 de agosto de 2018. <br></br>
               <a href="https://idatadev.com.br/417-2/"  target="_blank" > Clique aqui para ler na integra o termo </a></span>
         

         <button className="button" id="bt_from" type="submit">Cadastrar Conta</button>

        </form>
        
         <br></br>
       
    
        </div>
  
            </div>
          </div>
        </div>

  
        </div>


    
      );

      
}



