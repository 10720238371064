import React, { Component } from 'react';
import BrowserDetection from 'react-browser-detection';

const browserHandler = {
  default: (browser) => <div>{
    
    browser,
      
    localStorage.removeItem('bw', 'browser'),
    localStorage.setItem('bw', browser)   
   

    }</div>

};


export default class App extends Component {


  render() {
    return (
      <BrowserDetection>
        { browserHandler }
    
      </BrowserDetection>


    );
  }
}