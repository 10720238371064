
import React, {useState, useEffect, StyleSheet, View, Image} from 'react';
import { FiLogIn, FiPower, FiTrash2, FiSend, FiCheck, FiShoppingCart } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import './styles.css';
import api from '../services/api';
import imglogo from '../../img/b1.png';
import Helmet from 'react-helmet';



//import fotoproduto from 'http://www.idatadev.com.br/imgbig/1_';


export default function Profile() {

  const [cod_item, setcod_item] = useState('');
  const [nome_produtos, setnome_produtos] = useState('');
  const [valor_uni, setvalor_uni] = useState('');
  const [total_uni, settotal_uni] = useState('');
  const [codpro, setcodpro] = useState('');
  const [queryprodutos, setqueryprodutos] = useState([]); 
  const [querytotal, setquerytotal] = useState([]);  
  const cod_empresa = 112;
  const [querypedidototalqtde, setquerypedidototalqtde] = useState([]);
  const [qtde, setqtde] = useState([1]);
  const [qtde1, setqtde1] = useState([1]);
  
  const [querypedido, setquerypedido] = useState([]);  
  const [stipo, settipo] = useState(["TODOS"]);

  const history =useHistory();

 // busca dados do select
 
   useEffect(() => {
    {
   
      if (stipo == 'TODOS') 
      {
    api.get('Getregsitro_promocao',{ }).then ( response => {
     setqueryprodutos(response.data); 
    })
  }
}

   }, [stipo]);

   // fim busca

   const nome = localStorage.getItem('nome');
   const email = localStorage.getItem('email');
   const status = 'NOVO_WEB';
   const codcon = localStorage.getItem('codcon');
   const cod_emp = 112;
   const cod_ped_web =  localStorage.getItem('cod_ped_web');
   const qtde_count = '';
  
  const obs_item = '';
    

   const heder_ped = {
    nome,
    email,
    status,
    codcon,
    cod_emp,
    cod_ped_web,
    };

  

   const pedtotqtde = {
    
    cod_ped_web, 

   };
 

return(
  
 


<div  className="geral">  

<div className="cabeca">
    <h5>Lista de Participantes Promoção: Caixa som JBL Clip 3 - Aniversário IDATADEV SISTEMAS</h5>
    <Helmet title="Cadastre-se GRATUITAMENTE e concorra: Caixa som JBL Clip 3 - Aniversário IDATADEV SISTEMAS - Capinzal - SC" />
    
    
</div>     

<ul>        
   {queryprodutos.map(produto => (
     <li className="form-greg" >   
     <li  key={queryprodutos.codigo} /> 
      <p value={cod_item} 
             onChange={e => setcod_item(e.target.value)} > CUPOM NÚMERO: {produto.codigo}  <p></p>
             <strong>  {produto.nome}</strong></p> 
   
   
   
               
</li> 
 ))}
 
</ul>


</div>


);

}
