import React, {useState, useEffect, useCallback, StyleSheet, View, Image} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import './styles.css';
import { render } from "react-dom";
import api from '../../services/api';
import { Zoom , Slide} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { format, parseISO } from 'date-fns';
import Helmet from 'react-helmet';
import ReactWhatsapp from 'react-whatsapp';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import InputMask from "react-input-mask";
import styled from "styled-components";
import { mask as masker, unMask } from "remask";
import { useMediaQuery } from 'react-responsive';


const Layout = styled.div`
  padding: 12px;
`;

const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 12px;
  margin-top : 5px;
  font-size: 1.1rem;
`;

const InputMask2 = ({ mask, onChange, value, ...props }) => {
  const handleChange = ev => {
    const originalValue = unMask(ev.target.value);
    // const maskedValue = masker(originalValue, mask);
    onChange(originalValue);
  };

  const handleValue = masker(value, mask);

  return <Input {...props} onChange={handleChange} value={handleValue} />;
};


  export default function Profile() {

    const [value, setValue] = useState("");

    const [telefone, settelefone] = useState('');
  

  const [count, setcount] = useState([0]);
  const [cod_item, setcod_item] = useState('');
  const [nome_p, setnome_produtos] = useState('');
  const [valor, setvalor_uni] = useState('');
  const [total_uni, settotal_uni] = useState('');
  const [codpro, setcodpro] = useState('');
  const [cod_promo, setcod_promo] = useState('');

  const [listap, setqueryprodutos] = useState([]); 
  const [querylistacodigo, setquerylistacodigo] = useState([]);  
  const [querylistaregulamento, setquerylistaregulamento] = useState([]); 
  const [querylistapremios, setquerylistapremios] = useState([]);   
  const cod_empresa = 112;
  const [querypedidototalqtde, setquerypedidototalqtde] = useState([]);
  const [qtde, setqtde] = useState([1]);
  const [qtde1, setqtde1] = useState([1]);
  
 const [stipo, settipo] = useState(["TODOS"]);

//  const history =useHistory();
const[email, setEmail] = useState('');
const[nome, Setnome] = useState('');

  
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }



  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

 
  async function  handlePeriodChange(selVal) {
    this.props.handlePeriodChange(selVal);
}


async function  buscacodigo(e){
  e.preventDefault();
try{
const response = await api.get('GetConsultaCodigos/'+ cod_promo+'/'+email );
setquerylistacodigo(response.data);
 
} catch(err){
alert('Nenhum código foi encontrado neste promoção: '+cod_promo);
}
}

async function  buscapremios(e){
  e.preventDefault();
try{
const response = await api.get('GetConsultaPremios/'+ cod_promo );
setquerylistapremios(response.data);
 
} catch(err){
alert('Nenhum prêmio foi encontrado neste promoção: '+cod_promo);
}
}

async function  buscaregulamento(e){
  e.preventDefault();
try{



console.log(cod_promo)
const response = await api.get('GetConsultaRegulamento/'+ cod_promo );
console.log(response);
console.log(response.data);
setquerylistaregulamento(response.data);


} catch(err){
alert('Nenhum regulamento foi encontrado neste promoção: '+cod_promo);
}
}
  

 // busca dados do select
 
async function handdleLogin(e){
        e.preventDefault();
    try{
     
      console.log(email)

      const response = await api.get('GetConsultaCupons/'+ email );
      console.log(response);
      setqueryprodutos(response.data);
       
       
       localStorage.setItem('nome', response.data[0].nome);
       localStorage.setItem('email', response.data[0].cod_empresa);
      // localStorage.setItem('cod_promo', response.data[0].cod_promo);
    
      } catch(err){
      alert('Nenhum cupom foi encontrado neste CPF: '+email);
    }
  }


   // fim busca    

  
   
return(  

  
<div className="geral">
<Helmet title="Consulte seus cupons promocional - IDATADEV SISTEMAS" />
     <h4>  QRCODE PROMOCIONAL </h4>
     <h5>Consulte seus cupons ativos</h5>           
     <div className="div72">
                  
   <form onSubmit={handdleLogin} className="f1"> 

    <Layout>
    <div> <b>Digite o CPF ou CNPJ</b>
    </div>

      <InputMask2
        type="text"
        name="doc"
        mask={["999.999.999-99", "99.999.999/9999-99"]}
        placeholder="Digite o CPF ou CNPJ"
        onChange={setEmail}
        value={email}
      />
      <p></p>
         <button className="button" type="submit">Consultar Cupons</button>
         </Layout>

     </form>
    

{

  isDesktopOrLaptop && 
<div class="semgrid">         
   {listap.map(produto => (
     <li className="form-greg" justify-content= "center" >   
     <li  key={produto.codigo} /> 
      
      <div id="div1"> 
      <p id= "cod_item" 
             onChange={e => setcod_item(e.target.value)}>Emp.: {produto.nome}</p>
        <h6><p id="nome_produto" textvalue={produto.cod_promo} > { produto.cod_promo } - {produto.nome_promocao}</p></h6>
        <h5>CUPONS ATIVOS: {produto.total }  <br></br></h5> <h6>Data Sorteio: {format(parseISO(produto.pre_data_sorteio), 'dd/MM/yyyy')     } </h6>      
       
       { isDesktopOrLaptop && 

<div id="div3"> 
<img className="img_desc"
  srcSet={'https://bdimgqrcode.s3.sa-east-1.amazonaws.com/promo_geral_'+produto.cod_empresa+'_'+produto.cod_promo+'.jpg'}    
  />

  <form onSubmit={buscacodigo} className="f1">   
  <button className="buttonver" onClick={e => setcod_promo(e.target.value)} value={produto.cod_promo} type="Submit">SEUS CUPONS</button> 
  </form>     
   {querylistacodigo.map(codigos => (
     <li className="codigos_qrcode" justify-content= "center" >   
     <li  key={codigos.codigo} /> 
        <h7><p id="nome_produto" textvalue={codigos.codigo} > <b>CUPOM: { codigos.codigo + '\n'} </b>
        Data Ativação: {format(parseISO(codigos.pre_cad_data_cad), 'dd/MM/yyyy hh:mm:ss') } </p> </h7>
      </li> 
    ))}

    
  </div>
    }
    </div>  
   
         
</li> 
 ))}
</div>
}


{isTabletOrMobileDevice && 
  <div class="semgrid">         
   {listap.map(produto => (
     <li className="form-greg2" >   
     <li  key={produto.codigo} /> 
     
      <div id="div1mb"> 
      <p id= "cod_item" 
        onChange={e => setcod_item(e.target.value)}>Emp.: {produto.nome}</p>
      <h6><p id="nome_produto" textvalue={produto.cod_promo} >{produto.cod_promo} - {produto.nome_promocao}</p></h6>

  
      <h5>CUPONS ATIVOS: {produto.total }   </h5> <h6>Data Sorteio:             
            {   
            
            format(parseISO(produto.pre_data_sorteio), 'dd/MM/yyyy') 
        // Intl.DateTimeFormat('pt-BR').format(produto.pre_data_sorteio) 
   }     
        </h6>   
        <div id="div2"> 
    <img className="img_mobile"
      srcSet={'https://bdimgqrcode.s3.sa-east-1.amazonaws.com/promo_geral_'+produto.cod_empresa+'_'+produto.cod_promo+'.jpg'}    
      /> 

<form onSubmit={buscacodigo} className="f1">   
  <button className="buttonver" onClick={e => setcod_promo(e.target.value)} value={produto.cod_promo} type="Submit">SEUS CUPONS</button> 
  </form>     
   {querylistacodigo.map(codigos => (
     <li className="codigos_qrcode" justify-content= "center" >   
     <li  key={codigos.codigo} /> 
        <h7><p id="nome_produto" textvalue={codigos.codigo} > <b> CUPOM: { codigos.codigo + '\n'}  </b> 
        Data ativação: {format(parseISO(codigos.pre_cad_data_cad), 'dd/MM/yyyy hh:mm:ss')     } </p></h7>  
      </li> 
    ))}


      </div>
    </div>
          
</li> 
 ))}
 
</div>
}
 </div>


</div>

    
);

}
