import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';


import Consultacupom from './pages/qrcode/Consultacupom';
import empresas_ativas_qrcode from './pages/qrcode/empresas_ativas_qrcode';

import registro_extrato_promocao from './pages/registro_extrato_promocao';
import registro_promocao from './pages/registro_promocao';

import registro_extrato from './pages/registro_extrato';
import registro_qrcode from './pages/registro_qrcode';
import Consultasaldocashback from './pages/qrcode/Consultasaldocashback';
import Consultasaldocashbackemp from './pages/qrcode/Consultasaldocashbackemp';
import LoginCashback from './pages/qrcode/LoginCashback';
import Registro_conta from './pages/qrcode/Registro_conta';
import Registro_conta_aplicador from './pages/qrcode/Registro_conta_aplicador';


// Inicio Geral sistemas prefeitura
import Login from './pages/educacao/controle/login';
import Dashboard from './pages/educacao/controle/dashboard';
import Chamados from './pages/educacao/controle/chamados';
import Novochamado from './pages/educacao/controle/novochamado';
import Editarchamado from './pages/educacao/controle/editarchamado';

// biblioteca_capinzal
import Dashboard_biblioteca from './pages/educacao/controle/dashboard_biblioteca';
import Biblioteca_central from './pages/educacao/controle/biblioteca_central';

// Fim Geral sistemas dashbord


//fidelidade
import fidelidade_viver_mais from './pages/fidelidade_viver_mais';
import Consultar_farmacia from './pages/fidelidade/Consultar_farmacia';
import farmacia_grupo_med_farma from './pages/fidelidade/farmacia_grupo_med_farma';


export default function Routes(){
return(
    <BrowserRouter>
    <Switch>
  
  

        <Route path= "/registro_promocao" component={registro_promocao} />
        <Route path= "/registro_extrato_promocao" component={registro_extrato_promocao} />

        <Route path= "/registro_extrato" component={registro_extrato} />
        <Route path= "/registro_qrcode" component={registro_qrcode} />
       

 <Route path= "/qrcode/Consultasaldocashback" component={Consultasaldocashback} />
  <Route path= "/qrcode/Consultacupom" component={Consultacupom} />
  <Route path= "/qrcode/LoginCashback/:id" component={LoginCashback} />
  <Route path= "/qrcode/Registro_conta" component={Registro_conta} />
  <Route path= "/qrcode/Consultasaldocashbackemp" component={Consultasaldocashbackemp} />
  <Route path= "/qrcode/Registro_conta_aplicador/:id_qrcode" component={Registro_conta_aplicador} />
        <Route path= "/qrcode/empresas_ativas_qrcode" component={empresas_ativas_qrcode} />
        <Route path= "/fidelidade/Consultar_farmacia" component={Consultar_farmacia} />
        <Route path= "/fidelidade_viver_mais" component={fidelidade_viver_mais} />
        <Route path= "/fidelidade/farmacia_grupo_med_farma" component={farmacia_grupo_med_farma} />

    
        <Route path= "/educacao/controle/login/" component={Login} />
        <Route path= "/educacao/controle/dashboard/" component={Dashboard} />
        <Route path= "/educacao/controle/chamados/" component={Chamados} />
        <Route path= "/educacao/controle/novochamado/" component={Novochamado} />
        <Route path= "/educacao/controle/editarchamado/:id_chamado" component={Editarchamado} />

        <Route path= "/educacao/controle/dashboard_biblioteca/" component={Dashboard_biblioteca} />
        <Route path= "/educacao/controle/biblioteca_central/" component={Biblioteca_central} />
       
               
    </Switch>
    </BrowserRouter>
       
      );


}

