import React, {useState, useEffect, useCallback, StyleSheet, View, Image} from 'react';
import { FiLogIn, FiPower, FiTrash2, FiSend, FiArrowLeft, FiCheck, fiwhatsapp, FiShoppingCart } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import './estilo_dashboard.css';
import { render } from "react-dom";
import api from '../../../services/api';
import { Zoom , Slide} from 'react-slideshow-image';
import { format, parseISO } from 'date-fns';
import Helmet from 'react-helmet';
import ReactWhatsapp from 'react-whatsapp';
import InputMask from "react-input-mask";
import styled from "styled-components";
import { mask as masker, unMask } from "remask";
import { useMediaQuery } from 'react-responsive';

const Layout = styled.div`
  padding: 12px;
`;
const Input = styled.input`
  padding: 12px;
  margin-top : 5px;
  font-size: 1.1rem;
`;

const InputMask2 = ({ mask, onChange, value, ...props }) => {
  const handleChange = ev => {
    const originalValue = unMask(ev.target.value);
    // const maskedValue = masker(originalValue, mask);
    onChange(originalValue);
  };

  const handleValue = masker(value, mask);

  return <Input {...props} onChange={handleChange} value={handleValue} />;
};


export default function Login(){

  const [value, setValue] = useState("");
  const [telefone, settelefone] = useState('');
  const [dataexpiracao, setdata] = useState('');

//  const [cpf, setCpf] = useState('');
//  const [cod_empresa, setcod_empresa] = useState('');
const [nome_cliente, setnome_cliente] = useState('');
const [cargo, setcargo] = useState('');
const [cod_usuario, setcod_usuario] = useState('');


localStorage.getItem('nome_cliente', nome_cliente);
localStorage.getItem('cargo', cargo);
localStorage.getItem('cod_usuario', cod_usuario);

  const history = useHistory();
  const [listap, setqueryempresa] = useState([]); 

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }

  const cod_empresa =  localStorage.getItem('cod_empresa');


  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

 
  async function  handlePeriodChange(selVal) {
    this.props.handlePeriodChange(selVal);
}


function consultarchamado(e) {
  e.preventDefault();
  history.push('/educacao/controle/chamados');
}

function novochamado(e) {
  e.preventDefault();

  if (localStorage.getItem('cargo', cargo)  === 'FORNECEDOR'){
    alert('SEM ACESSO PARA ABRIR CHAMADO');
  } 
  else
  {
    history.push('/educacao/controle/Novochamado');

  }

}

function sairevento(e) {
  e.preventDefault();
  history.push('/educacao/controle/login');

}

async function LoginRegister(e){
  e.preventDefault();

const data = {
telefone,
value
};
}
//  <img className="" srcSet={'https://idatadev.com.br/sistema/chamados/logo.jpeg'}  />  
   
    return (
      <>
        <div className="principal_login">
        <div className="principal_login_novo">   

        <h3> Menu Principal Portal</h3>     
        <h6>Logado: <br></br> {localStorage.getItem('cod_usuario', cod_usuario)} - {localStorage.getItem('nome_cliente', nome_cliente)}<br></br>Cargo: {localStorage.getItem('cargo', cargo)}</h6>

        <Helmet title="Opções para usuário - IDATADEV SISTEMAS" />      
        
         <form onSubmit={novochamado}>       
         <Layout>
         <button className="btn" type="submit">NOVO CHAMADO</button>
         </Layout>
         </form>

         <form onSubmit={consultarchamado}>     
         <Layout>
         <button type="submit" className="btn" type="">CONSULTAR CHAMADO</button>
          </Layout>
         </form>
        
          <form onSubmit={consultarchamado}>     
         <Layout>
         <button type="submit" className="btn" type="">EDITAR CHAMADO</button>
         </Layout>
        
         </form>
         <form onSubmit={sairevento}>      
         <Layout>
         <button className="btn" type="submit">Sair</button>
          </Layout>
         </form>

       </div>
       </div>
       
      
        
<div className="rodape1">
        <Link to={{ pathname: "https://idatadev.com.br" }} target="_blank" > &copy;2022 - IDataDev Sistemas Computacionais  <br></br> Capinzal - SC - CNPJ: 22.463.183/0001-14</Link>
      
 </div>
 
       </>  


  );
}


