
import React, {useState, useEffect, useCallback, StyleSheet, View, Image} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../../../services/api';
import 'react-slideshow-image/dist/styles.css'
import ReactWhatsapp from 'react-whatsapp';
import Dropdown from 'react-dropdown';
import { useMediaQuery } from 'react-responsive';
import Select from 'react-select';
import 'react-dropdown/style.css';
import { format, parseISO } from 'date-fns';
import './styles_livros.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


//import fotoproduto from 'http://www.idatadev.com.br/imgbig/1_';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import { Check } from '@material-ui/icons';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));



export default function Profile() {

  const [count, setcount] = useState([0]);
  const [cod_item, setcod_item] = useState('');
  const [nome_p, setnome_produtos] = useState('');
  const [valor, setvalor_uni] = useState('');
  const [total_uni, settotal_uni] = useState('');
  const [codpro, setcodpro] = useState('');
  const [listap, setqueryprodutos] = useState([]); 
  const [querytotal, setquerytotal] = useState([]);  
  const cod_empresa = localStorage.getItem('cod_empresa');
  const [querypedidototalqtde, setquerypedidototalqtde] = useState([]);
  const [querylistaregulamento, setquerylistaregulamento] = useState([]); 
  const [qtde, setqtde] = useState([1]);
  const [qtde1, setqtde1] = useState([1]);
  
  const [cod_promo, setcod_promo] = useState([]);
  
  const [stipo, settipo] = useState(['Nome']);
  const [stval, setinputvalor] = useState([]);
  
  
 const [buscar, setbuscaparametro] = useState();

//  const history =useHistory();

  
 
  
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }



  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  async function  handlePeriodChange(selVal) {
    this.props.handlePeriodChange(selVal);
}

var data = '';

async function  buscarlivro(e){
  e.preventDefault();
try{

  if(stipo=='Nome')
  {
   var data = {
    nome_livro : stval
    };
  }

  if(stipo=='Autor')
  {
    var data = {
    autor : stval
    };
  }

  if(stipo=='Classificação')
  {
    var data = {
    classi : stval
    };
  }

  if(stipo=='Tipo')
  {
    var data = {
    tipo : stval
    };
  }


    const response = await api.post('getbusca_acervo',  data ).then ( response => {
     // console.log( JSON.stringify( response.data));

     if (response.data <= 0){
      alert('Nenhum  disponível no momento: ' +  stval);
      setcount(response.data.length);
     }
     else
 {
    setqueryprodutos(response.data); 
    setcount(response.data.length); 
 }

 
});
  
  
 
} 
catch(err){
alert('erro geral');
}

}


async function escolherfiltro()
   {

   } 
   

 // busca dados do select
   useEffect(() => {
    {
      const cpf2 =  localStorage.getItem('cpf');  
      const conta2 =  localStorage.getItem('conta'); 
      const cod_empresa2 =  localStorage.getItem('cod_empresa'); 

      //  cpf2 = replace(cpf2,'/','');
      //   cpf2 = cpf2.replace("/", "");
        console.log(conta2);
        console.log(cod_empresa2);
        var cpf3 = String(cpf2).replace('/','');
        var cpf3 = String(cpf3).replace('.','');
        var cpf3 = String(cpf3).replace('-','');
    
        api.post('getbusca_acervo/',{ }).then ( response => {
        setqueryprodutos(response.data); 
        setcount(response.data.length); 
       
    })
  }


}, [stipo]);

   // fim busca    
   const nome = localStorage.getItem('nome');
    const email = localStorage.getItem('email');
    const status = 'NOVO_WEB';
    const conta = localStorage.getItem('conta');
    const cpf =  localStorage.getItem('cpf');
    const qtde_count = '';
    const nome_cliente = localStorage.getItem('nome_cliente');
   
    
   const history = useHistory();    
    const obs_item = '';
    const nome_imagem ='';
   const heder_ped = {
    nome,
    email,
    status,
    conta,
    cod_empresa,
    cpf,
    };

  

   const pedtotqtde = {    
    cpf, 
   };
  

return(  

 <div className="LoginContainer1_b_l">
 <div className="pesquisa"> 
  <form onSubmit={buscarlivro} >
  <label> 
  <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Escolha tipo pesquisa</FormLabel>
          <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue="Nome"
          >
         <FormControlLabel value="Nome" control={<Radio /> }  onChange={e => settipo(e.target.value)} label="Nome" />
         <FormControlLabel value="Autor" control={<Radio />}  onChange={e => settipo(e.target.value)} label="Autor" />
         <FormControlLabel value="Classificação" control={<Radio />}  onChange={e => settipo(e.target.value)} label="Classificação" />
         <FormControlLabel value="Tipo" control={<Radio />}  onChange={e => settipo(e.target.value)} label="Tipo" />
        
         </RadioGroup>
         </FormControl>
        </label>
        
     <input  placeholder="Digite aqui..." 
          onChange={e => setinputvalor(e.target.value)}/>
  <button className="button" type="submit">Pesquisar</button>
  
  </form>

        
  </div> 
  
  
  <div className="info">
   <h5><p> Lista de Acervos</p> </h5>  
   <h8>Total exibidos em tela: {count} </h8>
  
  
  {
  isDesktopOrLaptop && 
<div class="grid-container">         
   {listap.map(produto => (
     <li className="form-greg" justify-content= "center" >   
     <li  key={produto.codigo} /> 
      
      <div id="div1"> 
      <p id= "cod_item" value={produto.CODIGO}  value={produto.CODIGO}
             onChange={e => setcod_item(e.target.value)} ></p> 
     <h7>Código: {produto.CODIGO} <br></br> Status: <b> {produto.STATUS}</b> </h7>
     <h5><p id="nome_produto" textvalue={produto.NOME} >{produto.NOME}</p></h5>
     <h6>Classificação:<p id="nome_produto" textvalue={produto.CLASSIFICAO} >{produto.CLASSIFICAO}</p></h6>
     <h6>Autor:<p id="nome_produto" textvalue={produto.AUTOR} >{produto.AUTOR}  </p></h6>
     
   <img className="img_livro"
   srcSet={'https://bdimgqrcode.s3.sa-east-1.amazonaws.com/db_biblioteca_1_'+produto.COD_PAI+'.jpg'}  
    />
     <ReactWhatsapp className="bt_troca" number={"'5549984354588'"} message={'Olá, gostaria de reservar este acervo: Código: ' +produto.CODIGO +' - '+produto.NOME }> Reservar </ReactWhatsapp>
     </div>     
</li> 
 ))}
</div>
}

{isTabletOrMobileDevice && 
  <div>         
   {listap.map(produto => (
     <li className="form_mobile2" >   
     <li  key={produto.CODIGO} /> 
      <div id="div1mb"> 
      <p id= "cod_item" value={produto.CODIGO}  value={produto.CODIGO}
             onChange={e => setcod_item(e.target.value)}> </p>
     <h7>Código: {produto.CODIGO} <br></br>Status:<b> {produto.STATUS}</b></h7> 
     <h5><p id="nome_produto" textvalue={produto.NOME} >{produto.NOME}</p></h5>
     <h6>Classificação:<p id="nome_produto" textvalue={produto.CLASSIFICAO} >{produto.CLASSIFICAO}</p></h6>
     <h6>Autor:<p id="nome_produto" textvalue={produto.AUTOR} >{produto.AUTOR}</p></h6>
  <img className="img_livro"
    srcSet={'https://bdimgqrcode.s3.sa-east-1.amazonaws.com/db_biblioteca_1_'+produto.COD_PAI+'.jpg'
  }
    />

     <ReactWhatsapp className="bt_troca" number={"'5549984354588'"} message={'Olá, gostaria de reservar este acervo: Código: ' +produto.CODIGO +' - '+produto.NOME }> Reservar</ReactWhatsapp>
   
   </div>
  </li> 
 )) }
</div>
 }
</div>

</div>
 
);
}
