
import React, {useState, useEffect, useCallback, StyleSheet, View, Image} from 'react';
import { Link, useHistory } from 'react-router-dom';

import api from '../../services/api';
import 'react-slideshow-image/dist/styles.css'
import ReactWhatsapp from 'react-whatsapp';

import Dropdown from 'react-dropdown';

import { useMediaQuery } from 'react-responsive';
import Select from 'react-select';
import 'react-dropdown/style.css';
import { format, parseISO } from 'date-fns';
import './styles.css';


//import fotoproduto from 'http://www.idatadev.com.br/imgbig/1_';


export default function Profile() {

  const [count, setcount] = useState([0]);
  const [cod_item, setcod_item] = useState('');
  const [nome_p, setnome_produtos] = useState('');
  const [valor, setvalor_uni] = useState('');
  const [total_uni, settotal_uni] = useState('');
  const [codpro, setcodpro] = useState('');
  const [listap, setqueryprodutos] = useState([]); 
  const [querytotal, setquerytotal] = useState([]);  
  const cod_empresa = localStorage.getItem('cod_empresa');
  const [querypedidototalqtde, setquerypedidototalqtde] = useState([]);
  const [querylistaregulamento, setquerylistaregulamento] = useState([]); 
  const [qtde, setqtde] = useState([1]);
  const [qtde1, setqtde1] = useState([1]);
  
  const [cod_promo, setcod_promo] = useState('');
  
 const [stipo, settipo] = useState(["TODOS"]);

//  const history =useHistory();

  
 
  
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }



  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

 
  async function  handlePeriodChange(selVal) {
    this.props.handlePeriodChange(selVal);
}



async function  buscaregulamento(e){
  e.preventDefault();
try{


const response = await api.get('GetConsultaRegulamento/'+ cod_promo );
setquerylistaregulamento(response.data);
} catch(err){
alert('Nenhum regulamento foi encontrado neste promoção: '+cod_promo);
}
}


async function escolherfiltro(parametro)
   {
   
 
     api.get('GetProdutostmf/'+parametro.value).then ( response => {
  

       if (response.data <= 0){
        alert('Nenhum produto disponível no momento: ' +  parametro.value);
       
       } 
       else
       {
        setqueryprodutos(response.data); 
       
       }
      //  console.log(marca);
     
     });
    }

   

 // busca dados do select
   useEffect(() => {
    {
      const cpf2 =  localStorage.getItem('cpf');  
      const conta2 =  localStorage.getItem('conta'); 
      const cod_empresa2 =  localStorage.getItem('cod_empresa'); 

      //  cpf2 = replace(cpf2,'/','');
      //   cpf2 = cpf2.replace("/", "");
        console.log(conta2);
        console.log(cod_empresa2);
        var cpf3 = String(cpf2).replace('/','');
        var cpf3 = String(cpf3).replace('.','');
        var cpf3 = String(cpf3).replace('-','');
      //  console.log(cpf3);
        
        
      //  console.log('GetConsultacashbackempresa/'+cpf3+'/'+conta2+'/'+cod_empresa2);

        api.get('GetConsultacashbackempresa/'+cpf3+'/'+conta2+'/'+cod_empresa2,{ }).then ( response => {
        setqueryprodutos(response.data); 
        setcount(response.data.length); 
       
    })
  }


}, [stipo]);

   // fim busca    
   const nome = localStorage.getItem('nome');
    const email = localStorage.getItem('email');
    const status = 'NOVO_WEB';
    const conta = localStorage.getItem('conta');
    const cpf =  localStorage.getItem('cpf');
    const qtde_count = '';
    const nome_cliente = localStorage.getItem('nome_cliente');
   
    
    const obs_item = '';
    const nome_imagem ='';

   const heder_ped = {
    nome,
    email,
    status,
    conta,
    cod_empresa,
    cpf,
    };

  

   const pedtotqtde = {    
    cpf, 
   };
  

return(  

  <div className="geral">

<nav class="navbar navbar-icon-top navbar-expand-lg navbar-dark bg-dark">
<div class="navbar-nav mr-auto">  
<div class="nav-item">
<Link class="nav-link">
           <div class="fa fa-user">
           </div> {nome_cliente} </Link>

</div>
</div>

  {/* inativado por enaunto/> 
/* <div class="navbar-nav mr-auto"> 
<div class="nav-item">
<Link class="nav-link" to= {"/qrcode/Consultasaldocashback"}>
           <div class="fa fa-cart-arrow-down">
           </div> TODOS MEUS CASH BACK </Link>

</div>
</div> */}


<div class="navbar-nav mr-auto">  
<div class="nav-item">
<Link class="nav-link" to= {"/qrcode/LoginCashback/"+cod_empresa} >
           <div class="fa fa-sign-out">
               <span class="badge badge-success"></span>
           </div> Logout </Link>

</div>
</div>

</nav>

<div className="slide-container">
   
<h5>BEM VINDO: <b> {nome_cliente} </b></h5>    
<div> 
  <h8>Total empresas encontradas: {count} </h8>

  {
  isDesktopOrLaptop && 
<div class="grid-container">         
   {listap.map(produto => (
     <li className="form-greg" justify-content= "center" >   
     <li  key={produto.codigo} /> 
      
      <div id="div1"> 
      <p id= "cod_item" value={produto.cpf}  value={produto.cpf}
             onChange={e => setcod_item(e.target.value)} > CPF: {produto.cpf} - Código Conta: {produto.codigo}</p>
     <h5>Empresa:<p id="nome_produto" textvalue={produto.nome_empresa} >{produto.nome_empresa}</p></h5>
     <h6>Promoção:<p id="nome_produto" textvalue={produto.nome_promocao} >{produto.nome_promocao}</p></h6>
     <h5>Pendente: {Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(produto.pendente ?? '0') } </h5> 
     <h4>SALDO LIBERADO: {Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(produto.liberado ?? '0') } </h4> 
     </div>   
     <div id="img1">    
     {isDesktopOrLaptop && 

  <img className="imggreg"
      srcSet={'https://dadosidatadev.s3.sa-east-1.amazonaws.com/promo_geral_'+produto.cod_empresa+'_'+produto.cod_promo+'.jpg'}    
  alt=""
    /> }
 <ReactWhatsapp className="bt_troca" number={"'"+produto.telefone+"'"} message={'Empresa: ' +produto.nome_empresa + ' - MEU CPF: ' + produto.cpf + ' Trocar meu CASH BACK R$: ' +produto.liberado }> Trocar Cash Back?</ReactWhatsapp>
  
<form onSubmit={buscaregulamento} className="">   
  <button className="buttonver" onClick={e => setcod_promo(e.target.value)} value={produto.cod_promo} type="Submit">Regulamento</button> 
  </form>     
   {querylistaregulamento.map(regulamento => (
     <li className="codigos_qrcode" justify-content= "center" >   
     <li  key={regulamento.codigo} /> 
        <h7><p id="nome_produto" textvalue={regulamento.codigo} > <b>Regulamento: { regulamento.regulamento_resumido_cashback } </b> </p></h7>
        <h7><p id="nome_produto" textvalue={regulamento.data_publicacao_regulamento} > Data efetivação regulamento: {  format(parseISO(regulamento.data_publicacao_regulamento), 'dd/MM/yyyy hh:mm:ss')     }</p></h7>   
      </li> 
    ))}  
    </div>
</li> 
 ))}
</div>
}


{isTabletOrMobileDevice && 
  <div>         
   {listap.map(produto => (
     <li className="form-greg2" >   
     <li  key={produto.codigo} /> 
      <div id="div1mb"> 
      <p id= "cod_item" value={produto.cpf}  value={produto.cpf}
             onChange={e => setcod_item(e.target.value)} > CPF: {produto.cpf} - Código Conta: {produto.codigo}</p>
     <h7>Empresa:<p id="nome_produto" textvalue={produto.nome_empresa} >{produto.nome_empresa}</p></h7>
     <h6>Promoção:<p id="nome_produto" textvalue={produto.nome_promocao} >{produto.nome_promocao}</p></h6>
     <h5>Pendente: {Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(produto.pendente ?? '0') } </h5> 
     <h4>SALDO LIBERADO: {Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(produto.liberado ?? '0') } </h4> 
     </div>

     <div id="div2"> 
    <img className="img_mobile"
      srcSet={'https://bdimgqrcode.s3.sa-east-1.amazonaws.com/promo_geral_'+produto.cod_empresa+'_'+produto.cod_promo+'.jpg'}    
      /> 
    </div>

    <ReactWhatsapp className="bt_troca" number={"'"+produto.telefone+"'"} message={'Empresa: ' +produto.nome_empresa + ' - MEU CPF: ' + produto.cpf + ' Trocar meu CASH BACK R$: ' +produto.liberado }> Trocar Cash Back?</ReactWhatsapp>
  
    <form onSubmit={buscaregulamento} className="">   
  <button className="buttonver" onClick={e => setcod_promo(e.target.value)} value={produto.cod_promo} type="Submit">Regulamento</button> 
  </form>     
   {querylistaregulamento.map(regulamento => (
     <li className="codigos_qrcode" justify-content= "center" >   
     <li  key={regulamento.codigo} /> 
        <h7><p id="nome_produto" textvalue={regulamento.codigo} > <b>Regulamento: { regulamento.regulamento_resumido_cashback } </b> </p></h7>
        <h7><p id="nome_produto" textvalue={regulamento.data_publicacao_regulamento} > Data efetivação regulamento: {  format(parseISO(regulamento.data_publicacao_regulamento), 'dd/MM/yyyy hh:mm:ss')     }</p></h7>   
      </li> 
    ))}

  
</li> 

))
 

 }

<br></br> <div className="rodape">
 <Link  href="https://www.instagram.com/idatadev">&copy;2021 - Plataforma S2QRCODE</Link>

</div>

 
</div>
}

</div>
</div>
</div>

    
);

}
