import React, {useState, useEffect, useCallback, StyleSheet, View, Image} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import './estilo_login.css';
import { render } from "react-dom";
import api from '../../../services/api';
import 'react-slideshow-image/dist/styles.css'
import { format, parseISO } from 'date-fns';
import Helmet from 'react-helmet';
import ReactWhatsapp from 'react-whatsapp';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import InputMask from "react-input-mask";
import styled from "styled-components";
import { mask as masker, unMask } from "remask";
import { useMediaQuery } from 'react-responsive';

const Layout = styled.div`
  padding: 12px;
`;
const Input = styled.input`
  padding: 12px;
  margin-top : 5px;
  font-size: 1.1rem;
`;


const InputMask2 = ({ mask, onChange, value, ...props }) => {
  const handleChange = ev => {
    const originalValue = unMask(ev.target.value);
    // const maskedValue = masker(originalValue, mask);
    onChange(originalValue);
  };

  const handleValue = masker(value, mask);

  return <Input {...props} onChange={handleChange} value={handleValue} />;
};


export default function Login(){

  const [value, setValue] = useState("");
  const [telefone, settelefone] = useState('');
 
//  const [cpf, setCpf] = useState('');
//  const [cod_empresa, setcod_empresa] = useState('');
const [nome_cliente, setnome_cliente] = useState('');
const [cargo, setcargo] = useState('');
  
  const history = useHistory();
  const [listap, setqueryempresa] = useState([]); 

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }

  const cod_empresa =  localStorage.getItem('cod_empresa');

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

 
  async function  handlePeriodChange(selVal) {
    this.props.handlePeriodChange(selVal);
}


async function LoginRegister(e){
  e.preventDefault();

const data = {
telefone,
value
};

try{
  console.log(value);
  console.log(telefone);

  const response = await api.get('GetLoginChamados/'+value+'/'+telefone);
  console.log(response.data);

localStorage.setItem('cpf', response.data[0].login);
localStorage.setItem('conta', response.data[0].codigo);
localStorage.setItem('nome_cliente', response.data[0].nome);
localStorage.setItem('cod_empresa', response.data[0].cod_empresa);
localStorage.setItem('cod_usuario', response.data[0].cod_usuario);
localStorage.setItem('cargo', response.data[0].cargo);

if (response.data[0].login == ''){
  alert('Login inválido');   
  }  
   else
   history.push('/educacao/controle/dashboard');


} catch(err){
  alert('Erro de accesso');
}}

    return (
        <>
        <div className="principal_login">
        <div className="principal_login_novo">   
       
        <img className="img" srcSet={'https://idatadev.com.br/sistema/chamados/logo.jpeg'}  />  
   
        <h5><p>BEM VINDO CONTROLE CHAMADOS</p></h5>
        <Helmet title="Efetue o login para acesso chamados - IDATADEV SISTEMAS" />      
        <form onSubmit={LoginRegister}>       
         <Layout>
         <div> <b>Digite Login ou e-mail</b></div>
         <input type='text'  name="doc" value={value} placeholder="Digite o Login ou e-mail" 
          onChange={e => setValue(e.target.value)} required />

         <div><b>Senha de acesso</b></div>
         <input type='password' value={telefone} placeholder="Senha acesso" 
          onChange={e => settelefone(e.target.value)} required />
         <button className="btn" type="submit">Login</button>
         </Layout>
        </form>
      
       </div>
       
       </div>
       <div className="rodape1">
        &copy;2022 - IDataDev Sistemas Computacionais  <br></br> CNPJ:22.463.183/0001-14      
        </div>
       </>
            
      
       
    
  

  );
}


