
import React, {useState, useEffect, useCallback, StyleSheet, View, Image} from 'react';
import { FiLogIn, FiPower, FiTrash2, FiSend, FiCheck, fiwhatsapp, FiShoppingCart } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { render } from "react-dom";
import './styles.css';
import { Zoom , Slide} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { format, parseISO } from 'date-fns';
import ReactWhatsapp from 'react-whatsapp';
import Helmet from 'react-helmet';




import { useMediaQuery } from 'react-responsive';
import 'react-dropdown/style.css';

export default function Profile() {

  const [cod_item, setcod_item] = useState('');
  const [listap, setqueryprodutos] = useState([]); 
  const [querytotal, setquerytotal] = useState([]);  
  const cod_empresa = 112;
  
   const [cep, setcep] = useState(['']);
   const[cidade, setcidade] = useState('');

  
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }



  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

 
  async function  handlePeriodChange(selVal) {
    this.props.handlePeriodChange(selVal);
}

useEffect(() => {
  {
 
  api.get('GetConsultaempresas_qrcode').then ( response => {
   setqueryprodutos(response.data);
  })

 }
}, 
[cidade]);

  
 // busca dados do select
 
async function handdleLogin(e){
        e.preventDefault();
    try{
     
      console.log(cep);
      const response = await api.get('GetConsultaempresas_qrcode_filtro/'+ cep );
      console.log(response);
      setqueryprodutos(response.data);
                 
       if (response.data == '')
          alert('Nenhuma empresa foi encontrada no banco de dados com este parâmetro: '+cep);
       
      } catch(err){
      //  alert('Nenhuma farmácia foi encontrada com este parâmetro: '+cep);
        api.get('GetConsultaempresas_qrcode').then ( response => {
          setqueryprodutos(response.data);
         })
    }
  }


   // fim busca    

  
   
return(  

  
  <div className="body1">  
      <Helmet title="Empresas ativas com Plataforma Gestão Promocional - www.idatadev.com.br" /> 
      <div className="cabecalho"> 
     <h4>Empresas ativas Plataforma Gestão Promocional</h4> 
    </div> 

   < form onSubmit={handdleLogin} className="form-pesquisa">   
   
   < input id="cep" className="input_pesquisa"  type="cep"  placeholder="CEP ou CIDADE" 
     value={cep}
     maxLength="14"
     onChange={e => setcep(e.target.value)}
   />
   
   <button className="button5" type="Submit">Consultar emprasas</button> 
   </form>
  
  <br></br>   




  {isDesktopOrLaptop && 
<div class="textodiv">         
   {listap.map(produto => (
     <li className="form-greg" justify-content= "center" >      
      <div id="div1"> 
      <p id= "cod_item" value={cod_item}  value={produto.cod_empresa}
             onChange={e => setcod_item(e.target.value)} > Código: {produto.cod_empresa}</p>
     <h5><p id="nome_produto" textvalue={produto.nome} >{produto.nome}</p></h5>
     <p>Endereço: {produto.endereco}   
     <br></br> Cidade: {produto.cidade} - UF: {produto.estado} - CEP: {produto.cep} 
     <br></br>E-mail: {produto.email} - Telefone: {produto.fone}   </p>
     </div>
     <div id="img1">    
     {isDesktopOrLaptop && 
   
  <img className="imglist_pc"
  srcSet={'https://idatadev.com.br/sistema/qrcode/logos/'+produto.cod_empresa+'.png'}  
  alt=""
    /> }

   <ReactWhatsapp className="btgreg" number="" message={'Empresa:' +produto.nome + ' - Gostaria de saber mais informação...'}> Entre em contato com empresa   </ReactWhatsapp>
   
    </div>
</li> 
 ))}
</div>
}


{isTabletOrMobileDevice && 
  <div class="textodiv">         
   {listap.map(produto => (
     <li className="form-greg2" >   
     <li  key={produto.cod_empresa} /> 
     
      <div id="div1mb"> 
      <p id= "cod_item" value={cod_item}  value={produto.cod_empresa}
             onChange={e => setcod_item(e.target.value)} >
               </p>
     <h5><p id="nome_produto" textvalue={produto.nome} >{produto.nome}</p></h5>
    
     <p>Endereço: {produto.endereco}   
     <br></br> Cidade: {produto.cidade} - UF: {produto.estado} - CEP: {produto.cep} 
     <br></br>E-mail: {produto.email} - Telefone: {produto.fone}   </p>
     </div>

     <div id="div2"> 
    <img className="imglist_novo"
      srcSet={'https://idatadev.com.br/sistema/qrcode/logos/'+produto.cod_empresa+'.png'}    
      alt=""
    /> 
    </div>
   
    <ReactWhatsapp className="btgreg" number={"'"+produto.whats+"'"} message={'Empresa:' +produto.nome + ' - Gostaria de saber mais informação...'}> Entre em contato com empresa    </ReactWhatsapp>
   
</li> 
 ))}
 
</div>
}
 

 

<div className="rodape">
        <Link  href="https://www.instagram.com/idatadev">&copy;2021 - IDataDev Sistemas Computacionais <br></br>Capinzal - SC</Link>

</div>

</div>

    
);

}
