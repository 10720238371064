import React, {useState} from 'react';
import Routes from './routes';
import './global.css';

function App() {

  return (
      
      <Routes/>
  );
}

export default App;
